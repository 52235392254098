import React from "react";
import { Box, Button, Container, Grid, Typography, Link } from "@mui/material";
import etherscanIcon from "../public/etherscan.png";
import openseaIcon from "../public/opensea.png";
import { ThemeProvider } from "@mui/material/styles";
import { Poppins } from "../components/utils/FontTheme";

const TicketCard = ({
  imageUrl,
  title,
  artist,
  productionDate,
  artistPageUrl,
  contractAddress,
  tokenId,
}) => {
  const openArtistPage = () => {
    window.open(artistPageUrl, "_blank");
  };

  return (
    <>
      <Container sx={{ py: 3 }}>
        <Box
          sx={{
            borderRadius: 3,
            boxShadow: 10,
            backgroundColor: "#FFFFFF",
            p: 2,
            maxWidth: 300,
            display: "block",
            margin: "auto",
            mb: 1,
          }}
        >
          <Box
            component="img"
            sx={{
              width: 130,
              display: "block",
              m: "auto",
              mb: 1,
            }}
            alt="medalImage"
            src={imageUrl}
          />
          <Typography component="div" sx={{ mb: 1 }}>
            {`「${title}」`}
          </Typography>
          <Typography noWrap component="div">
            {`作者：${artist}`}
          </Typography>
          <Typography noWrap component="div">
            {`製作年：${productionDate}`}
          </Typography>
          {artistPageUrl && (
            <>
              <Button
                sx={{
                  color: "#868686",
                  display: "block",
                  margin: "auto",
                  mt: 1,
                }}
                onClick={openArtistPage}
              >
                アーティスト紹介ページへ
              </Button>
            </>
          )}
        </Box>
        <Box sx={{ maxWidth: 300, display: "block", margin: "auto" }}>
          <Grid container spacing={2} alignItems="center" justify="center">
            <Grid item xs={6}>
              <Button
                variant="contained"
                href={`https://etherscan.io/address/${contractAddress}`}
                target="_blank"
                sx={{
                  color: "#000000",
                  backgroundColor: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                  },
                  height: 30,
                  width: 140,
                  borderRadius: 10,
                  my: 2,
                  boxShadow: 10,
                  textAlign: "center",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: 100,
                  }}
                  alt="etherscanIcon"
                  src={etherscanIcon}
                />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                href={`https://opensea.io/assets/ethereum/${contractAddress}/${tokenId}`}
                target="_blank"
                sx={{
                  color: "#000000",
                  backgroundColor: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                  },
                  height: 30,
                  width: 140,
                  borderRadius: 10,
                  my: 2,
                  boxShadow: 10,
                  textAlign: "center",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: 80,
                  }}
                  alt="openseaIcon"
                  src={openseaIcon}
                />
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ maxWidth: 300, display: "block", margin: "auto" }}>
          <ThemeProvider theme={Poppins}>
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#FFF",
              }}
            >
              Contract
            </Typography>
            <Typography
              sx={{
                wordWrap: "break-word",
                color: "#FFF",
              }}
            >
              {contractAddress}
            </Typography>
          </ThemeProvider>
        </Box>
      </Container>
    </>
  );
};

export default TicketCard;
