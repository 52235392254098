import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import ImageList from "@mui/material/ImageList";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Medal from "../components/Medal";
import Loading from "./utils/Loading";
import { useTorus } from "../hooks/torusWalletConnect";

export default function MedalList() {
  const { walletAddress } = useTorus();
  const [medalNfts, setMedalNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMedalNft = async ({ walletAddress }) => {
    setIsLoading(true);
    const res = await API.get("saunoaRestApi", "/medals", {
      queryStringParameters: {
        walletAddress,
      },
    });
    console.log(res);
    setMedalNfts(res.nft);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!walletAddress) {
      return;
    }
    fetchMedalNft({ walletAddress });
  }, [walletAddress]);

  if (!medalNfts.length) {
    return (
      <>
        <Typography sx={{ textAlign: "center", my: 3, fontSize: 16 }}>
          所有NFTメダル
        </Typography>
        <>
          {isLoading ? (
            <Loading text="読み込み中" />
          ) : (
            <Typography
              sx={{
                textAlign: "center",
                my: 3,
                color: "#868686",
              }}
            >
              なし
            </Typography>
          )}
        </>
      </>
    );
  }
  return (
    <>
      <Box sx={{ my: 3, pb: 5 }}>
        <Typography sx={{ textAlign: "center", fontSize: 16 }}>
          所有NFTメダル
        </Typography>
        <ImageList sx={{ width: 320, margin: "auto" }} cols={3} gap={1}>
          {medalNfts.map((nft, index) => (
            <Medal
              nft={nft}
              key={`${nft.contract_address}-${nft.token_id}-${index}`}
            />
          ))}
        </ImageList>
      </Box>
    </>
  );
}
