import React from "react";
import { Box, Container, Link, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Poppins } from "../components/utils/FontTheme";
import { Link as RouterLink } from "react-router-dom";
import FotterSAUNOA from "../public/FotterSAUNOA.svg";

const Footer = () => {
  return (
    <Container maxWidth="false" sx={{ bgcolor: "#7C7C7C", p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          component="img"
          sx={{
            width: 180,
            display: "block",
            margin: "auto",
            mb: 3,
          }}
          alt="logo"
          src={FotterSAUNOA}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ m: 2, mx: 1 }}>
          <Link
            href="https://line.me/R/ti/p/@131benua"
            target="_blank"
            underline="hover"
            sx={{ color: "white", fontSize: 14 }}
          >
            LINEへ戻る
          </Link>
        </Box>
        <Box sx={{ m: 2, mx: 1 }}>
          <Link
            href="https://saunoa.hacomono.jp"
            target="_blank"
            underline="hover"
            sx={{ color: "white", fontSize: 14 }}
          >
            予約サイトへ
          </Link>
        </Box>
        <Box sx={{ m: 2, mx: 1 }}>
          <Link
            href="https://note.com/saunoa281/n/na59ae4eade95"
            target="_blank"
            underline="hover"
            sx={{ color: "white", fontSize: 14 }}
          >
            使い方
          </Link>
        </Box>
        {/* 退会機能が不要になったのでコメントアウト */}
        {/* <Box sx={{ m: 2 }}>
          <Link
            component={RouterLink}
            to="/withdrawal"
            underline="hover"
            sx={{ color: "white" }}
          >
            退会について
          </Link>
        </Box> */}
      </Box>

      <ThemeProvider theme={Poppins}>
        <Typography
          sx={{
            color: "#FFFFFF",
            m: 2,
            textAlign: "center",
          }}
        >
          Copyright © Ambient Works Inc.
        </Typography>
      </ThemeProvider>
    </Container>
  );
};

export default Footer;
