import logo from "./logo.svg";
import "./App.css";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import Box from "@mui/material/Box";
import Home from "./components/Home";
import Footer from "./components/Footer";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import Withdrawal from "./components/Withdrawal";
import WithdrawalComplete from "./components/WithdrawalComplete";
import Header from "./components/Header";
import WalletConnect from "./components/WalletConnect";
import { useTorus } from "./hooks/torusWalletConnect";

Amplify.configure(awsExports);

function App() {
  const { provider } = useTorus();
  return (
    <>
      <BrowserRouter basename="/">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100vw",
            height: "100vh",
          }}
        >
          <Header />
          <Box sx={{ flex: 1 }}>
            <Routes>
              <Route
                path="/"
                element={<>{provider ? <Home /> : <WalletConnect />}</>}
              />
              <Route
                path="/withdrawal"
                element={<>{provider ? <Withdrawal /> : <WalletConnect />}</>}
              />
              <Route
                path="/withdrawal/complete"
                element={
                  <>{provider ? <WithdrawalComplete /> : <WalletConnect />}</>
                }
              />
            </Routes>
          </Box>

          <Footer />
        </Box>
      </BrowserRouter>
    </>
  );
}

export default App;
