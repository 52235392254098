import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import Box from "@mui/material/Box";
import { Typography, Button, Grid } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Loading from "./utils/Loading";
import { useTorus } from "../hooks/torusWalletConnect";

export default function Withdrawal() {
  const navigate = useNavigate();
  const { walletAddress, signMessage } = useTorus();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const submitWithdrawal = async () => {
    setIsLoading(true);
    const data = {
      walletAddress: walletAddress,
    };
    const message = JSON.stringify(data);
    const signature = await signMessage(message);
    const res = await API.post("saunoaRestApi", "/withdrawal", {
      body: {
        message,
        signature,
        walletAddress,
      },
    });
    console.log(res);
    if (res.success) {
      setOpen(false);
      setIsLoading(false);
      navigate("/withdrawal/complete");
    }
  };

  return (
    <>
      <Box
        sx={{
          m: 3,
          mt: 5,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography>
          以下のボタンは別途会員ページでの退会処理が終わったのちに押してください。
        </Typography>
      </Box>
      <Box
        sx={{
          m: 3,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography>
          このボタンを押すと、お客さまの持っている会員券NFTがバーン（焼却）されます。
        </Typography>
      </Box>
      <Box
        sx={{
          m: 3,
          display: "flex",
          justifyContent: "center",
        }}
      ></Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              outline: 0,
              width: 250,
            }}
          >
            <Box
              sx={{
                borderRadius: 3,
                boxShadow: 10,
                backgroundColor: "#FFFFFF",
                p: 2,
                maxWidth: 300,
                display: "block",
                margin: "auto",
                mb: 1,
              }}
            >
              {isLoading ? (
                <>
                  <Loading />
                </>
              ) : (
                <>
                  <Typography
                    component="div"
                    sx={{ mb: 1, textAlign: "center" }}
                  >
                    本当に退会しますか？
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        sx={{
                          color: "#000",
                          backgroundColor: "#FFFFFF",
                          "&:hover": {
                            backgroundColor: "#FFFFFF",
                          },
                          height: 30,
                          width: 100,
                          border: 1,
                          borderRadius: 10,
                          my: 2,
                          textAlign: "center",
                        }}
                        onClick={submitWithdrawal}
                      >
                        はい
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        sx={{
                          color: "#000",
                          backgroundColor: "#FFFFFF",
                          "&:hover": {
                            backgroundColor: "#FFFFFF",
                          },
                          height: 30,
                          width: 100,
                          border: 1,
                          borderRadius: 10,
                          my: 2,
                          textAlign: "center",
                        }}
                        onClick={() => setOpen(false)}
                      >
                        いいえ
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Box>
        <Button
          onClick={() => setOpen(true)}
          sx={{
            color: "#FFFFFF",
            backgroundColor: "#000000",
            "&:hover": {
              backgroundColor: "#000001",
            },
            height: 45,
            width: "80%",
            maxWidth: 300,
            borderRadius: 10,
            display: "block",
            margin: "auto",
            mb: 5,
            boxShadow: 10,
            textTransform: "none",
            display: "flex",
            textAlign: "center",
            textJustify: "center",
          }}
        >
          退会する
        </Button>
      </Box>
    </>
  );
}
