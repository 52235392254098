import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Box, Grid, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Poppins } from "./utils/FontTheme";

const StatusBar = ({ walletAddress }) => {
  const [ratio, setRatio] = useState();
  const [next, setNext] = useState();
  const [previous, setPrevious] = useState();

  const fetchStatus = async () => {
    const res = await API.get("saunoaRestApi", "/status", {
      queryStringParameters: {
        walletAddress: walletAddress,
      },
    });
    console.log(res);
    const visitCount = res.visit;
    const thresholds = res.thresholds;
    let previous = 0;
    let next = 0;
    for (const threshold of thresholds) {
      next = threshold;
      if (visitCount >= previous && visitCount <= next) {
        break;
      }
      previous = threshold;
    }
    setNext(next);
    setPrevious(previous);
    if (next === previous) {
      setRatio(100);
    } else {
      const percent = parseInt(
        ((visitCount - previous) / (next - previous)) * 100
      );
      setRatio(percent);
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", right: "30" }}>
        <ThemeProvider theme={Poppins}>
          <Typography>When's next...?</Typography>
        </ThemeProvider>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            width: 350,
            maxWidth: "80%",
            height: 50,
            background: `linear-gradient(90deg, #000000 0%, #000000 ${ratio}%, #ffffff ${ratio}%, #ffffff 100%)`,
            border: "3px solid grey",
            borderRadius: 5,
          }}
        ></Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            width: 350,
            maxWidth: "80%",
            height: 50,
            display: "flex",
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <ThemeProvider theme={Poppins}>
                <Typography>{previous}</Typography>
              </ThemeProvider>
            </Grid>
            <Grid item xs={6}>
              <ThemeProvider theme={Poppins}>
                <Typography sx={{ textAlign: "right" }}>{next}</Typography>
              </ThemeProvider>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default StatusBar;
