import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ImageListItem from "@mui/material/ImageListItem";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import MedalCard from "../components/MedalCard";

export default function Medal({ nft }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = useState();
  const [artist, setArtist] = useState();
  const [productionDate, setProductionDate] = useState();
  const [artistPage, setArtistPage] = useState();

  useEffect(() => {
    for (const attribute of nft.metadata.attributes) {
      if (!("trait_type" in attribute && "value" in attribute)) {
        continue;
      }
      if (attribute.trait_type === "Title") {
        setTitle(attribute.value);
      } else if (attribute.trait_type === "Artist") {
        setArtist(attribute.value);
      } else if (attribute.trait_type === "Production-Date") {
        setProductionDate(attribute.value);
      } else if (attribute.trait_type === "Artist-Page") {
        setArtistPage(attribute.value);
      }
    }
  }, [nft]);

  return (
    <>
      <Button onClick={handleOpen}>
        <ImageListItem
          sx={{
            ".MuiImageListItem-img": {
              borderRadius: 2,
            },
          }}
          key={nft.metadata.image}
        >
          <img
            src={nft.metadata.image}
            srcSet={nft.metadata.image}
            loading="lazy"
          />
        </ImageListItem>
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              outline: 0,
            }}
          >
            <MedalCard
              imageUrl={nft.metadata.image}
              title={title}
              artist={artist}
              productionDate={productionDate}
              artistPageUrl={artistPage}
              contractAddress={nft.contract_address}
              tokenId={nft.token_id}
            ></MedalCard>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
